/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, Optional, EventEmitter, isDevMode, Component, HostBinding, Output, ViewChild, ContentChild, ElementRef, Injectable, InjectionToken, Inject, NgModule } from '@angular/core';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { PreventableEvent, focusableSelector, Keys, isDocumentAvailable, closest, hasObservers, ResizeBatchService } from '@progress/kendo-angular-common';
import * as i1$1 from '@progress/kendo-angular-popup';
import { PopupService } from '@progress/kendo-angular-popup';
import { take, auditTime, filter } from 'rxjs/operators';
import { Subscription, BehaviorSubject, Subject, combineLatest, fromEvent } from 'rxjs';
import { validatePackage } from '@progress/kendo-licensing';
import { NgIf, NgStyle, NgClass, NgTemplateOutlet } from '@angular/common';
import { xIcon } from '@progress/kendo-svg-icons';
import { IconWrapperComponent, IconsService } from '@progress/kendo-angular-icons';

/**
 * @hidden
 */
const _c0 = ["popoverWrapper"];
const _c1 = ["titleTemplateWrapper"];
const _c2 = ["bodyTemplateWrapper"];
const _c3 = (a0, a1) => ({
  "width": a0,
  "height": a1
});
const _c4 = (a0, a1) => ({
  $implicit: a0,
  data: a1
});
function PopoverComponent_div_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", ctx_r0.getCalloutPosition());
  }
}
function PopoverComponent_div_0_div_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function PopoverComponent_div_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, PopoverComponent_div_0_div_3_ng_container_1_Template, 1, 0, "ng-container", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const noCallout_r2 = i0.ɵɵreference(5);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", noCallout_r2);
  }
}
function PopoverComponent_div_0_ng_template_4_div_0_2_ng_template_0_Template(rf, ctx) {}
function PopoverComponent_div_0_ng_template_4_div_0_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PopoverComponent_div_0_ng_template_4_div_0_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.titleTemplate == null ? null : ctx_r0.titleTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c4, ctx_r0.anchor, ctx_r0.contextData));
  }
}
function PopoverComponent_div_0_ng_template_4_div_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.title, " ");
  }
}
function PopoverComponent_div_0_ng_template_4_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 14, 2);
    i0.ɵɵtemplate(2, PopoverComponent_div_0_ng_template_4_div_0_2_Template, 1, 5, null, 15)(3, PopoverComponent_div_0_ng_template_4_div_0_ng_container_3_Template, 2, 1, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.titleTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.title && !ctx_r0.titleTemplate);
  }
}
function PopoverComponent_div_0_ng_template_4_div_1_2_ng_template_0_Template(rf, ctx) {}
function PopoverComponent_div_0_ng_template_4_div_1_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PopoverComponent_div_0_ng_template_4_div_1_2_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.bodyTemplate == null ? null : ctx_r0.bodyTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c4, ctx_r0.anchor, ctx_r0.contextData));
  }
}
function PopoverComponent_div_0_ng_template_4_div_1_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(4);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.body, " ");
  }
}
function PopoverComponent_div_0_ng_template_4_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 17, 3);
    i0.ɵɵtemplate(2, PopoverComponent_div_0_ng_template_4_div_1_2_Template, 1, 5, null, 15)(3, PopoverComponent_div_0_ng_template_4_div_1_ng_container_3_Template, 2, 1, "ng-container", 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.bodyTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.body && !ctx_r0.bodyTemplate);
  }
}
function PopoverComponent_div_0_ng_template_4_div_2_1_ng_template_0_Template(rf, ctx) {}
function PopoverComponent_div_0_ng_template_4_div_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PopoverComponent_div_0_ng_template_4_div_2_1_ng_template_0_Template, 0, 0, "ng-template", 16);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.actionsTemplate == null ? null : ctx_r0.actionsTemplate.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction2(2, _c4, ctx_r0.anchor, ctx_r0.contextData));
  }
}
function PopoverComponent_div_0_ng_template_4_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 18);
    i0.ɵɵtemplate(1, PopoverComponent_div_0_ng_template_4_div_2_1_Template, 1, 5, null, 15);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.actionsTemplate);
  }
}
function PopoverComponent_div_0_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, PopoverComponent_div_0_ng_template_4_div_0_Template, 4, 2, "div", 11)(1, PopoverComponent_div_0_ng_template_4_div_1_Template, 4, 2, "div", 12)(2, PopoverComponent_div_0_ng_template_4_div_2_Template, 2, 1, "div", 13);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r0.titleTemplate || ctx_r0.title);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.bodyTemplate || ctx_r0.body);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.actionsTemplate);
  }
}
function PopoverComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5, 0);
    i0.ɵɵtemplate(2, PopoverComponent_div_0_div_2_Template, 1, 1, "div", 6)(3, PopoverComponent_div_0_div_3_Template, 2, 1, "div", 7)(4, PopoverComponent_div_0_ng_template_4_Template, 3, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const noCallout_r2 = i0.ɵɵreference(5);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction2(4, _c3, ctx_r0.width, ctx_r0.height));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.callout);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.callout)("ngIfElse", noCallout_r2);
  }
}
const _c5 = (a0, a1) => ({
  $implicit: a0,
  anchor: a1
});
function TooltipContentComponent_div_2_ng_template_1_Template(rf, ctx) {}
function TooltipContentComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtemplate(1, TooltipContentComponent_div_2_ng_template_1_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.titleTemplate)("ngTemplateOutlet", ctx_r0.titleTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction2(3, _c5, ctx_r0.anchor, ctx_r0.anchor));
  }
}
function TooltipContentComponent_ng_template_3_Template(rf, ctx) {}
function TooltipContentComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.templateString, " ");
  }
}
function TooltipContentComponent_div_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("click", function TooltipContentComponent_div_5_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.onCloseClick($event));
    });
    i0.ɵɵelementStart(1, "a", 9);
    i0.ɵɵelement(2, "kendo-icon-wrapper", 10);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-hidden", true);
    i0.ɵɵadvance();
    i0.ɵɵattribute("title", ctx_r0.closeButtonTitle);
    i0.ɵɵadvance();
    i0.ɵɵproperty("svgIcon", ctx_r0.xIcon);
  }
}
function TooltipContentComponent_div_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 11);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.calloutPositionClass());
  }
}
class LocalizedMessagesDirective extends ComponentMessages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoTooltipLocalizedMessages", ""]],
  inputs: {
    closeTitle: "closeTitle"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([{
    provide: ComponentMessages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: ComponentMessages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: `[kendoTooltipLocalizedMessages]`,
      standalone: true
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, {
    closeTitle: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
const ERRORS = {
  popover: `Invalid value provided for the 'popover' property. The accepted data types are 'PopoverComponent' or 'PopoverFn'.`,
  templateData: `templateData must be a function, but received`,
  showOn: `Invalid value provided for the 'showOn' property. The available options are 'click', 'hover', 'focus' or 'none'.`
};

/**
 * Arguments for the `show` event. The `show` event fires when a popover is about
 * to be opened. If you cancel the event, the opening is prevented.
 */
class PopoverShowEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `show` event.
   * @param anchor - The host element related to the popover.
   */
  constructor(anchor) {
    super();
    this.anchor = anchor;
  }
}
/**
 * Arguments for the `hide` event. The `hide` event fires when a popover is about
 * to be closed. If you cancel the event, the popover stays open.
 */
class PopoverHideEvent extends PreventableEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `hide` event.
   * @param anchor - The host element related to the popover.
   * @param popover - The popover element.
   */
  constructor(anchor, popover) {
    super();
    this.anchor = anchor;
    this.popover = popover;
  }
}
/**
 * Arguments for the `shown` event. The `shown` event fires after the popover has opened and its opening animation has finished.
 */
class PopoverShownEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `shown` event.
   * @param anchor - The host element related to the popover.
   * @param popover - The popover element.
   */
  constructor(anchor, popover) {
    this.anchor = anchor;
    this.popover = popover;
  }
}
/**
 * Arguments for the `hidden` event. The `hidden` event fires after the popover has closed and its closing animation has finished.
 */
class PopoverHiddenEvent {
  /**
   * @hidden
   * Constructs the event arguments for the `hidden` event.
   * @param anchor - The host element related to the popover.
   */
  constructor(anchor) {
    this.anchor = anchor;
  }
}

/**
 * @hidden
 */
let idx = 0;
/**
 * @hidden
 */
let popoverTitleIdx = 0;
/**
 * @hidden
 */
let popoverBodyIdx = 0;
/**
 * @hidden
 */
const getId = (prefix, idSource) => {
  switch (idSource) {
    case 'popoverTitle':
      return `${prefix}-${++popoverTitleIdx}`;
    case 'popoverBody':
      return `${prefix}-${++popoverBodyIdx}`;
    default:
      return `${prefix}-${++idx}`;
  }
};
/**
 * @hidden
 */
function align(position, offset) {
  let anchorAlign = {};
  let popupAlign = {};
  let popupMargin = {};
  switch (position) {
    case 'top':
      anchorAlign = {
        horizontal: 'center',
        vertical: 'top'
      };
      popupAlign = {
        horizontal: 'center',
        vertical: 'bottom'
      };
      popupMargin = {
        horizontal: 0,
        vertical: offset
      };
      break;
    case 'bottom':
      anchorAlign = {
        horizontal: 'center',
        vertical: 'bottom'
      };
      popupAlign = {
        horizontal: 'center',
        vertical: 'top'
      };
      popupMargin = {
        horizontal: 0,
        vertical: offset
      };
      break;
    case 'right':
      anchorAlign = {
        horizontal: 'right',
        vertical: 'center'
      };
      popupAlign = {
        horizontal: 'left',
        vertical: 'center'
      };
      popupMargin = {
        horizontal: offset,
        vertical: 0
      };
      break;
    case 'left':
      anchorAlign = {
        horizontal: 'left',
        vertical: 'center'
      };
      popupAlign = {
        horizontal: 'right',
        vertical: 'center'
      };
      popupMargin = {
        horizontal: offset,
        vertical: 0
      };
      break;
    default:
      break;
  }
  return {
    anchorAlign,
    popupAlign,
    popupMargin
  };
}
/**
 * @hidden
 */
function collision(inputcollision, position) {
  if (inputcollision) {
    return inputcollision;
  }
  if (position === 'top' || position === 'bottom') {
    return {
      horizontal: 'fit',
      vertical: 'flip'
    };
  }
  return {
    horizontal: 'flip',
    vertical: 'fit'
  };
}
function isDocumentNode(container) {
  return container.nodeType === 9;
}
/**
 * @hidden
 */
function closestBySelector(element, selector) {
  if (element.closest) {
    return element.closest(selector);
  }
  const matches = Element.prototype.matches ? (el, sel) => el.matches(sel) : (el, sel) => el.msMatchesSelector(sel);
  let node = element;
  while (node && !isDocumentNode(node)) {
    if (matches(node, selector)) {
      return node;
    }
    node = node.parentNode;
  }
}
/**
 * @hidden
 */
function contains(container, child) {
  if (!container) {
    return false;
  }
  if (isDocumentNode(container)) {
    return false;
  }
  if (container.contains) {
    return container.contains(child);
  }
  if (container.compareDocumentPosition) {
    return !!(container.compareDocumentPosition(child) & Node.DOCUMENT_POSITION_CONTAINED_BY);
  }
}
/**
 * @hidden
 */
const hasParent = (node, parent) => {
  while (node && node !== parent) {
    node = node.parentNode;
  }
  return node;
};
/**
 * @hidden
 */
function getCenterOffset(item, dir, size) {
  const rect = item.getBoundingClientRect();
  return rect[dir] + rect[size] / 2;
}
/**
 * @hidden
 */
function containsItem(collection, item) {
  return collection.indexOf(item) !== -1;
}
/**
 * @hidden
 */
function getAllFocusableChildren(parent) {
  return parent.querySelectorAll(focusableSelector);
}
/**
 * @hidden
 */
function getFirstAndLastFocusable(parent) {
  const all = getAllFocusableChildren(parent);
  const firstFocusable = all.length > 0 ? all[0] : parent;
  const lastFocusable = all.length > 0 ? all[all.length - 1] : parent;
  return [firstFocusable, lastFocusable];
}

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-tooltip',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1724741889,
  version: '16.8.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * Represents a template that defines the content of the Popover title.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverTitleTemplate` directive inside the `<kendo-popover>` tag.
 */
class PopoverTitleTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
PopoverTitleTemplateDirective.ɵfac = function PopoverTitleTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverTitleTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
PopoverTitleTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverTitleTemplateDirective,
  selectors: [["", "kendoPopoverTitleTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverTitleTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPopoverTitleTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the content of the Popover body.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverBodyTemplate` directive inside the `<kendo-popover>` tag.
 */
class PopoverBodyTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
PopoverBodyTemplateDirective.ɵfac = function PopoverBodyTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverBodyTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
PopoverBodyTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverBodyTemplateDirective,
  selectors: [["", "kendoPopoverBodyTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverBodyTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPopoverBodyTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents a template that defines the content of the Popover actions.
 *
 * To define the template, nest an `<ng-template>` tag
 * with the `kendoPopoverActionsTemplate` directive inside the `<kendo-popover>` tag.
 */
class PopoverActionsTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
PopoverActionsTemplateDirective.ɵfac = function PopoverActionsTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverActionsTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
PopoverActionsTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverActionsTemplateDirective,
  selectors: [["", "kendoPopoverActionsTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverActionsTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPopoverActionsTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Represents the [Kendo UI Popover component for Angular]({% slug overview_popover %}).
 * Used to display additional information that is related to a target element.
 *
 * @example
 * ```ts-no-run
 * <kendo-popover>
 *    <ng-template kendoPopoverTitleTemplate>Foo Title</ng-template>
 *    <ng-template kendoPopoverBodyTemplate>Foo Body</ng-template>
 *    <ng-template kendoPopoverActionsTemplate>Foo Actions</ng-template>
 * </kendo-popover>
 * ```
 */
class PopoverComponent {
  constructor(localization, renderer, element, zone) {
    this.localization = localization;
    this.renderer = renderer;
    this.element = element;
    this.zone = zone;
    /**
     * Specifies the position of the Popover in relation to its anchor element. [See example]({% slug positioning_popover %})
     *
     * The possible options are:
     * `top`
     * `bottom`
     * `right` (Default)
     * `left`
     */
    this.position = 'right';
    /**
     * Determines whether a callout will be rendered along the Popover. [See example]({% slug callout_popover %})
     *
     * @default true
     */
    this.callout = true;
    /**
     * Enables and configures the Popover animation. [See example]({% slug animations_popover %})
     *
     * The possible options are:
     *
     * * `boolean`&mdash;Enables the default animation
     * * `PopoverAnimation`&mdash;A configuration object which allows setting the `direction`, `duration` and `type` of the animation.
     *
     * @default false
     */
    this.animation = false;
    /**
     * @hidden
     * Determines the visibility of the Popover.
     */
    this.visible = false;
    /**
     * Fires before the Popover is about to be shown ([see example]({% slug events_popover %})).
     * The event is preventable. If canceled, the Popover will not be displayed. [See example]({% slug events_popover %})
     */
    this.show = new EventEmitter();
    /**
     * Fires after the Popover has been shown and the animation has ended. [See example]({% slug events_popover %})
     */
    this.shown = new EventEmitter();
    /**
     * Fires when the Popover is about to be hidden ([see example]({% slug events_popover %})).
     * The event is preventable. If canceled, the Popover will remain visible.
     */
    this.hide = new EventEmitter();
    /**
     * Fires after the Popover has been hidden and the animation has ended. [See example]({% slug events_popover %})
     */
    this.hidden = new EventEmitter();
    /**
     * @hidden
     */
    this.closeOnKeyDown = new EventEmitter();
    /**
     * @hidden
     */
    this._width = 'auto';
    /**
     * @hidden
     */
    this._height = 'auto';
    /**
     * @hidden
     */
    this.popoverId = '';
    this._offset = 6;
    this.subs = new Subscription();
    this._templateData = () => null;
    validatePackage(packageMetadata);
  }
  /**
   * Specifies the distance from the Popover to its anchor element in pixels.
   *
   * @default `6`
   */
  set offset(value) {
    this._offset = value;
  }
  get offset() {
    const calloutBuffer = 14;
    return this.callout ? calloutBuffer + this._offset : this._offset;
  }
  /**
   * Determines the width of the popover. Numeric values are treated as pixels.
   * @default 'auto'
   */
  set width(value) {
    this._width = typeof value === 'number' ? `${value}px` : value;
  }
  get width() {
    return this._width;
  }
  /**
   * Determines the height of the popover. Numeric values are treated as pixels.
   * @default 'auto'
   */
  set height(value) {
    this._height = typeof value === 'number' ? `${value}px` : value;
  }
  get height() {
    return this._height;
  }
  /**
   * Defines a callback function which returns custom data passed to the Popover templates.
   * It exposes the current anchor element as an argument. [See example](slug:templates_popover#toc-passing-data-to-templates)
   */
  set templateData(fn) {
    if (isDevMode && typeof fn !== 'function') {
      throw new Error(`${ERRORS.templateData} ${JSON.stringify(fn)}.`);
    }
    this._templateData = fn;
  }
  get templateData() {
    return this._templateData;
  }
  /**
   * @hidden
   */
  get isHidden() {
    return !this.visible;
  }
  /**
   * @hidden
   */
  get hasAttributeHidden() {
    return !this.visible;
  }
  ngOnInit() {
    this.popoverId = getId('k-popover');
    this.subs.add(this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    }));
    this.subs.add(this.renderer.listen(this.element.nativeElement, 'keydown', event => this.onKeyDown(event)));
  }
  ngAfterViewInit() {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      if (this.visible) {
        const wrapper = this.popoverWrapper.nativeElement;
        const focusablePopoverChildren = getAllFocusableChildren(wrapper);
        if (focusablePopoverChildren.length > 0) {
          focusablePopoverChildren[0].focus();
        }
        this.setAriaAttributes(wrapper, focusablePopoverChildren);
      }
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  /**
   * @hidden
   */
  getCalloutPosition() {
    switch (this.position) {
      case 'top':
        return {
          'k-callout-s': true
        };
      case 'bottom':
        return {
          'k-callout-n': true
        };
      case 'left':
        return {
          'k-callout-e': true
        };
      case 'right':
        return {
          'k-callout-w': true
        };
      default:
        return {
          'k-callout-s': true
        };
    }
  }
  /**
   * @hidden
   */
  onKeyDown(event) {
    const keyCode = event.keyCode;
    const target = event.target;
    if (keyCode === Keys.Tab) {
      this.keepFocusWithinComponent(target, event);
    }
    if (keyCode === Keys.Escape) {
      this.closeOnKeyDown.emit();
    }
  }
  keepFocusWithinComponent(target, event) {
    const wrapper = this.popoverWrapper.nativeElement;
    const [firstFocusable, lastFocusable] = getFirstAndLastFocusable(wrapper);
    const tabAfterLastFocusable = !event.shiftKey && target === lastFocusable;
    const shiftTabAfterFirstFocusable = event.shiftKey && target === firstFocusable;
    if (tabAfterLastFocusable) {
      event.preventDefault();
      firstFocusable.focus();
    }
    if (shiftTabAfterFirstFocusable) {
      event.preventDefault();
      lastFocusable.focus();
    }
  }
  setAriaAttributes(wrapper, focusablePopoverChildren) {
    if (this.titleTemplate) {
      const titleRef = this.titleTemplateWrapper.nativeElement;
      const focusableHeaderChildren = getAllFocusableChildren(titleRef).length > 0;
      if (focusableHeaderChildren) {
        const headerId = getId('k-popover-header', 'popoverTitle');
        this.renderer.setAttribute(titleRef, 'id', headerId);
        this.renderer.setAttribute(wrapper, 'aria-labelledby', headerId);
      }
    }
    if (this.bodyTemplate) {
      const bodyRef = this.bodyTemplateWrapper.nativeElement;
      const focusableBodyChildren = getAllFocusableChildren(bodyRef).length > 0;
      if (focusableBodyChildren) {
        const bodyId = getId('k-popover-body', 'popoverBody');
        this.renderer.setAttribute(bodyRef, 'id', bodyId);
        this.renderer.setAttribute(wrapper, 'aria-describedby', bodyId);
      }
    }
    this.renderer.setAttribute(wrapper, 'id', this.popoverId);
    this.renderer.setAttribute(wrapper, 'role', focusablePopoverChildren.length > 0 ? 'dialog' : 'tooltip');
  }
}
PopoverComponent.ɵfac = function PopoverComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
PopoverComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PopoverComponent,
  selectors: [["kendo-popover"]],
  contentQueries: function PopoverComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PopoverTitleTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, PopoverBodyTemplateDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, PopoverActionsTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.titleTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bodyTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.actionsTemplate = _t.first);
    }
  },
  viewQuery: function PopoverComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
      i0.ɵɵviewQuery(_c1, 5);
      i0.ɵɵviewQuery(_c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.popoverWrapper = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.titleTemplateWrapper = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bodyTemplateWrapper = _t.first);
    }
  },
  hostVars: 8,
  hostBindings: function PopoverComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction)("aria-hidden", ctx.hasAttributeHidden);
      i0.ɵɵstyleProp("width", ctx._width)("height", ctx._height);
      i0.ɵɵclassProp("k-hidden", ctx.isHidden);
    }
  },
  inputs: {
    position: "position",
    offset: "offset",
    width: "width",
    height: "height",
    title: "title",
    subtitle: "subtitle",
    body: "body",
    callout: "callout",
    animation: "animation",
    templateData: "templateData"
  },
  outputs: {
    show: "show",
    shown: "shown",
    hide: "hide",
    hidden: "hidden",
    closeOnKeyDown: "closeOnKeyDown"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.popover'
  }]), i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  consts: [["popoverWrapper", ""], ["noCallout", ""], ["titleTemplateWrapper", ""], ["bodyTemplateWrapper", ""], ["class", "k-popover k-popup", 3, "ngStyle", 4, "ngIf"], [1, "k-popover", "k-popup", 3, "ngStyle"], ["class", "k-popover-callout", 3, "ngClass", 4, "ngIf"], ["class", "k-popover-inner", 4, "ngIf", "ngIfElse"], [1, "k-popover-callout", 3, "ngClass"], [1, "k-popover-inner"], [4, "ngTemplateOutlet"], ["class", "k-popover-header", 4, "ngIf"], ["class", "k-popover-body", 4, "ngIf"], ["class", "k-popover-actions k-actions k-actions-stretched k-actions-horizontal", 4, "ngIf"], [1, "k-popover-header"], [4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "k-popover-body"], [1, "k-popover-actions", "k-actions", "k-actions-stretched", "k-actions-horizontal"]],
  template: function PopoverComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PopoverComponent_div_0_Template, 6, 7, "div", 4);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", ctx.visible);
    }
  },
  dependencies: [NgIf, NgStyle, NgClass, NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-popover',
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.popover'
      }],
      template: `
    <div #popoverWrapper *ngIf="visible" class="k-popover k-popup" [ngStyle]="{'width': width, 'height': height}">
        <div class="k-popover-callout" [ngClass]="getCalloutPosition()" *ngIf="callout"></div>

        <div class="k-popover-inner" *ngIf="callout; else noCallout">
            <ng-container *ngTemplateOutlet="noCallout"></ng-container>
        </div>

        <ng-template #noCallout>
            <div #titleTemplateWrapper *ngIf="titleTemplate || title" class="k-popover-header">
                <ng-template *ngIf="titleTemplate"
                    [ngTemplateOutlet]="titleTemplate?.templateRef"
                    [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
                </ng-template>
                <ng-container *ngIf="title && !titleTemplate">
                    {{ title }}
                </ng-container>
            </div>

            <div #bodyTemplateWrapper *ngIf="bodyTemplate || body" class="k-popover-body">
                <ng-template *ngIf="bodyTemplate"
                    [ngTemplateOutlet]="bodyTemplate?.templateRef"
                    [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
                </ng-template>
                <ng-container *ngIf="body && !bodyTemplate">
                    {{ body }}
                </ng-container>
            </div>

            <div *ngIf="actionsTemplate" class="k-popover-actions k-actions k-actions-stretched k-actions-horizontal">
                <ng-template *ngIf="actionsTemplate"
                    [ngTemplateOutlet]="actionsTemplate?.templateRef"
                    [ngTemplateOutletContext]="{ $implicit: anchor, data: contextData }">
                </ng-template>
            </div>
        </ng-template>
    </div>
    `,
      standalone: true,
      imports: [NgIf, NgStyle, NgClass, NgTemplateOutlet]
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    position: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    title: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    body: [{
      type: Input
    }],
    callout: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    templateData: [{
      type: Input
    }],
    isHidden: [{
      type: HostBinding,
      args: ['class.k-hidden']
    }],
    hasAttributeHidden: [{
      type: HostBinding,
      args: ['attr.aria-hidden']
    }],
    show: [{
      type: Output
    }],
    shown: [{
      type: Output
    }],
    hide: [{
      type: Output
    }],
    hidden: [{
      type: Output
    }],
    closeOnKeyDown: [{
      type: Output
    }],
    popoverWrapper: [{
      type: ViewChild,
      args: ['popoverWrapper']
    }],
    titleTemplateWrapper: [{
      type: ViewChild,
      args: ['titleTemplateWrapper']
    }],
    bodyTemplateWrapper: [{
      type: ViewChild,
      args: ['bodyTemplateWrapper']
    }],
    titleTemplate: [{
      type: ContentChild,
      args: [PopoverTitleTemplateDirective, {
        static: false
      }]
    }],
    bodyTemplate: [{
      type: ContentChild,
      args: [PopoverBodyTemplateDirective, {
        static: false
      }]
    }],
    actionsTemplate: [{
      type: ContentChild,
      args: [PopoverActionsTemplateDirective, {
        static: false
      }]
    }],
    _width: [{
      type: HostBinding,
      args: ['style.width']
    }],
    _height: [{
      type: HostBinding,
      args: ['style.height']
    }]
  });
})();

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
const validShowOptions = ['hover', 'click', 'none', 'focus'];
/**
 * @hidden
 */
class PopoverDirectivesBase {
  constructor(ngZone, popupService, renderer) {
    this.ngZone = ngZone;
    this.popupService = popupService;
    this.renderer = renderer;
    /**
     * @hidden
     */
    this.anchor = null;
    this.subs = new Subscription();
    this._showOn = 'click';
  }
  /**
   * Specifies the popover instance that will be rendered.
   * Accepts a [`PopoverComponent`]({% slug api_tooltip_popovercomponent %}) instance or
   * a [`PopoverFn`]({% slug api_tooltip_popoverfn %}) callback which returns a [`PopoverComponent`]({% slug api_tooltip_popovercomponent %}) instance
   * depending on the current anchor element.
   *
   * [See example](slug:templates_popover#toc-passing-data-to-templates)
   */
  set popover(value) {
    if (value instanceof PopoverComponent || typeof value === `function`) {
      this._popover = value;
    } else {
      if (isDevMode) {
        throw new Error(ERRORS.popover);
      }
    }
  }
  get popover() {
    return this._popover;
  }
  /**
   * The event on which the Popover will be shown
   *
   * The supported values are:
   * - `click` (default) &mdash;The Popover will be shown when its `anchor` element is clicked.
   * - `hover`&mdash;The Popover will be shown when its `anchor` element is hovered.
   * - `focus`&mdash;The Popover will be shown when its `anchor` element is focused.
   * - `none`&mdash;The Popover will not be shown on user interaction. It could be rendered via the Popover API methods.
   */
  set showOn(value) {
    if (isDevMode && !containsItem(validShowOptions, value)) {
      throw new Error(ERRORS.showOn);
    }
    this._showOn = value;
  }
  get showOn() {
    return this._showOn;
  }
  ngAfterViewInit() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      switch (this.showOn) {
        case 'hover':
          this.subscribeToShowEvents([{
            name: 'mouseenter',
            handler: this.mouseenterHandler
          }, {
            name: 'mouseleave',
            handler: this.mouseleaveHandler
          }]);
          break;
        case 'focus':
          this.subscribeToShowEvents([{
            name: 'focus',
            handler: this.focusHandler
          }, {
            name: 'blur',
            handler: this.blurHandler
          }]);
          break;
        case 'click':
          this.subscribeClick();
          break;
        default:
          break;
      }
    });
  }
  ngOnDestroy() {
    this.closePopup();
    this.subs.unsubscribe();
    if (this.disposeHoverOverListener) {
      this.disposeHoverOverListener();
    }
    if (this.disposeHoverOutListener) {
      this.disposeHoverOutListener();
    }
    if (this.disposeClickListener) {
      this.disposeClickListener();
    }
    if (this._focusInsideSub) {
      this._focusInsideSub.unsubscribe();
    }
    if (this._hideSub) {
      this._hideSub.unsubscribe();
    }
    if (this._popupOpenSub) {
      this._popupOpenSub.unsubscribe();
    }
    if (this._popupCloseSub) {
      this._popupCloseSub.unsubscribe();
    }
  }
  /**
   * Hides the Popover ([See example]({% slug programmaticcontrol_popover %})).
   */
  hide() {
    this.closePopup();
  }
  /**
   * @hidden
   */
  closePopup() {
    if (this.popupRef) {
      if (this.anchor) {
        this.renderer.removeAttribute(this.anchor, 'aria-describedby');
      }
      this.popupRef.close();
      this.popupRef = null;
      if (this.disposePopupHoverOutListener) {
        this.disposePopupHoverOutListener();
      }
      if (this.disposePopupHoverInListener) {
        this.disposePopupHoverInListener();
      }
      if (this.disposePopupFocusOutListener) {
        this.disposePopupFocusOutListener();
      }
    }
  }
  /**
   * @hidden
   */
  openPopup(anchor) {
    this.anchor = anchor instanceof ElementRef ? anchor.nativeElement : anchor;
    const popoverComp = this.popover instanceof PopoverComponent ? this.popover : this.popover(this.anchor);
    const alignSettings = align(popoverComp.position, popoverComp.offset);
    const anchorAlign = alignSettings.anchorAlign;
    const popupAlign = alignSettings.popupAlign;
    const popupMargin = alignSettings.popupMargin;
    const _animation = popoverComp.animation;
    this.popupRef = this.popupService.open({
      anchor: {
        nativeElement: this.anchor
      },
      animate: _animation,
      content: PopoverComponent,
      popupAlign,
      anchorAlign,
      margin: popupMargin,
      popupClass: 'k-popup-transparent',
      collision: {
        horizontal: 'fit',
        vertical: 'fit'
      }
    });
    const popupInstance = this.popupRef.content.instance;
    if (anchor) {
      this.subs.add(this.renderer.listen(this.anchor, 'keydown', event => this.onKeyDown(event)));
      this.renderer.setAttribute(this.anchor, 'aria-describedby', popupInstance.popoverId);
    }
    this.subs.add(popupInstance.closeOnKeyDown.subscribe(() => {
      this.anchor.focus();
      this.hide();
    }));
    this.applySettings(this.popupRef.content, popoverComp);
    this.monitorPopup();
    this.initializeCompletionEvents(popoverComp, this.anchor);
  }
  /**
   * @hidden
   */
  isPrevented(anchorElement, show) {
    const popoverComp = this.popover instanceof PopoverComponent ? this.popover : this.popover(anchorElement);
    let eventArgs;
    // eslint-disable-next-line prefer-const
    eventArgs = this.initializeEvents(popoverComp, eventArgs, show, anchorElement);
    return eventArgs.isDefaultPrevented();
  }
  /**
   * @hidden
   */
  monitorPopup() {
    if (this.showOn === 'hover') {
      this.ngZone.runOutsideAngular(() => {
        const popup = this.popupRef.popupElement;
        this.disposePopupHoverInListener = this.renderer.listen(popup, 'mouseenter', _ => {
          this.ngZone.run(_ => this._popoverService.emitPopoverState(true));
        });
        this.disposePopupHoverOutListener = this.renderer.listen(popup, 'mouseleave', _ => {
          this.ngZone.run(_ => this._popoverService.emitPopoverState(false));
        });
      });
    }
    if (this.showOn === 'focus') {
      this.ngZone.runOutsideAngular(() => {
        const popup = this.popupRef.popupElement;
        this.disposePopupFocusOutListener = this.renderer.listen(popup, 'focusout', e => {
          const isInsidePopover = closest(e.relatedTarget, node => node.classList && node.classList.contains('k-popover'));
          if (!isInsidePopover) {
            this.ngZone.run(_ => this._popoverService.emitFocusInsidePopover(false));
          }
        });
      });
    }
  }
  applySettings(contentComponent, popover) {
    const content = contentComponent.instance;
    content.visible = true;
    content.anchor = this.anchor;
    content.position = popover.position;
    content.offset = popover.offset;
    content.width = popover.width;
    content.height = popover.height;
    content.title = popover.title;
    content.body = popover.body;
    content.callout = popover.callout;
    content.animation = popover.animation;
    content.contextData = popover.templateData(this.anchor);
    content.titleTemplate = popover.titleTemplate;
    content.bodyTemplate = popover.bodyTemplate;
    content.actionsTemplate = popover.actionsTemplate;
    this.popupRef.content.changeDetectorRef.detectChanges();
  }
  /**
   * @hidden
   */
  initializeEvents(popoverComp, eventArgs, show, anchorElement) {
    if (show) {
      eventArgs = new PopoverShowEvent(anchorElement);
      if (this.shouldEmitEvent(!!this.popupRef, 'show', popoverComp)) {
        this.ngZone.run(() => popoverComp.show.emit(eventArgs));
      }
    } else {
      eventArgs = new PopoverHideEvent(anchorElement, this.popupRef);
      if (this.shouldEmitEvent(!!this.popupRef, 'hide', popoverComp)) {
        this.ngZone.run(() => popoverComp.hide.emit(eventArgs));
      }
    }
    return eventArgs;
  }
  onKeyDown(event) {
    const keyCode = event.keyCode;
    if (keyCode === Keys.Escape) {
      this.hide();
    }
  }
  initializeCompletionEvents(popoverComp, _anchor) {
    if (this.shouldEmitCompletionEvents('shown', popoverComp)) {
      this.popupRef.popupOpen.subscribe(() => {
        const eventArgs = new PopoverShownEvent(_anchor, this.popupRef);
        popoverComp.shown.emit(eventArgs);
      });
    }
    if (this.shouldEmitCompletionEvents('hidden', popoverComp)) {
      this.popupRef.popupClose.subscribe(() => {
        this.ngZone.run(_ => {
          const eventArgs = new PopoverHiddenEvent(_anchor);
          popoverComp.hidden.emit(eventArgs);
        });
      });
    }
  }
  shouldEmitEvent(hasPopup, event, popoverComp) {
    if (event === 'show' && !hasPopup && hasObservers(popoverComp[event]) || event === 'hide' && hasPopup && hasObservers(popoverComp[event])) {
      return true;
    }
    return false;
  }
  shouldEmitCompletionEvents(event, popoverComp) {
    if (hasObservers(popoverComp[event]) && !this._popupOpenSub || hasObservers(popoverComp[event]) && !this._popupCloseSub) {
      return true;
    }
    return false;
  }
}
PopoverDirectivesBase.ɵfac = function PopoverDirectivesBase_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverDirectivesBase)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1$1.PopupService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
PopoverDirectivesBase.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverDirectivesBase,
  inputs: {
    popover: "popover",
    showOn: "showOn"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverDirectivesBase, [{
    type: Directive,
    args: [{}]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: i1$1.PopupService
    }, {
      type: i0.Renderer2
    }];
  }, {
    popover: [{
      type: Input
    }],
    showOn: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class PopoverService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this._pointerOverPopup = new BehaviorSubject(null);
    this._pointerOverAnchor = new BehaviorSubject(null);
    this._focusInsidePopover = new BehaviorSubject(null);
    this._hidePopover = new Subject();
    this.subs = new Subscription();
    this.monitor();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  get isPopoverHovered() {
    return this._pointerOverPopup.asObservable();
  }
  emitPopoverState(isHovered) {
    this.ngZone.run(_ => this._pointerOverPopup.next(isHovered));
  }
  get isAnchorHovered() {
    return this._pointerOverAnchor.asObservable();
  }
  emitAnchorState(isHovered, anchor) {
    this._isOrigin = this.originAnchor === anchor;
    this.currentAnchor = anchor;
    if (isHovered) {
      this.originAnchor = anchor;
    }
    this.ngZone.run(_ => this._pointerOverAnchor.next(isHovered));
  }
  get isFocusInsidePopover() {
    return this._focusInsidePopover.asObservable();
  }
  emitFocusInsidePopover(isFocused) {
    this.ngZone.run(_ => this._focusInsidePopover.next(isFocused));
    this._focusInsidePopover.next(null);
  }
  get hidePopover() {
    return this._hidePopover.asObservable();
  }
  monitor() {
    this.subs.add(combineLatest(this.isPopoverHovered, this.isAnchorHovered).pipe(
    // `auditTime` is used because the `mouseleave` event is emitted before `mouseenter`
    // i.e. there is a millisecond in which the pointer leaves the first target (e.g. anchor) and hasn't reached the second one (e.g. popup)
    // resulting in both observables emitting `false`
    auditTime(20)).subscribe(val => {
      const [isPopoverHovered, isAnchorHovered] = val;
      this._hidePopover.next([isPopoverHovered, isAnchorHovered, this._isOrigin, this.currentAnchor]);
    }));
  }
}
PopoverService.ɵfac = function PopoverService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverService)(i0.ɵɵinject(i0.NgZone));
};
PopoverService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PopoverService,
  factory: PopoverService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Represents the [`kendoPopoverAnchor`](slug:configuration_popover#toc-popover-anchor) directive.
 * It is used to target an element, which should display a popover on interaction.
 *
 * @example
 * ```ts-no-run
 * <button kendoPopoverAnchor [popover]="myPopover">Show Popover</button>
 * ```
 */
class PopoverAnchorDirective extends PopoverDirectivesBase {
  constructor(hostEl, ngZone, popupService, renderer, popoverService) {
    super(ngZone, popupService, renderer);
    this.hostEl = hostEl;
    this.ngZone = ngZone;
    this.popupService = popupService;
    this.renderer = renderer;
    this.popoverService = popoverService;
    this.mouseenterHandler = () => {
      this.controlVisibility(this.hostEl.nativeElement, true);
    };
    this.mouseleaveHandler = () => {
      if (this.isPrevented(this.hostEl.nativeElement, false)) {
        return;
      }
      if (!this._hideSub) {
        this._hideSub = this.popoverService.hidePopover.subscribe(val => {
          const [isPopoverHovered, isAnchorHovered] = val;
          if (!isPopoverHovered && !isAnchorHovered) {
            this.hide();
          }
        });
      }
    };
    this.focusHandler = () => {
      this.controlVisibility(this.hostEl.nativeElement, true);
    };
    this.blurHandler = args => {
      const event = args.domEvent;
      if (this.isPrevented(this.hostEl.nativeElement, false)) {
        return;
      }
      // from anchor to popup focus check
      const isFocusInside = !!closest(event.relatedTarget, node => node.classList && node.classList.contains('k-popover'));
      if (!isFocusInside) {
        this.hide();
      }
      if (!this._focusInsideSub) {
        // inside popup focus check
        this._focusInsideSub = this.popoverService.isFocusInsidePopover.pipe(filter(v => v !== null)).subscribe(val => {
          if (!val) {
            this.hide();
          }
        });
      }
    };
    this._popoverService = this.popoverService;
  }
  /**
   * Shows the Popover. [See example]({% slug programmaticcontrol_popover %})
   */
  show() {
    if (this.popupRef) {
      return;
    }
    this.ngZone.run(() => {
      this.openPopup(this.hostEl);
    });
    this.popupRef.popupAnchorViewportLeave.pipe(take(1)).subscribe(() => this.hide());
  }
  /**
   * Toggles the visibility of the Popover. [See example]({% slug programmaticcontrol_popover %})
   */
  toggle() {
    if (this.popupRef) {
      this.hide();
    } else {
      this.show();
    }
  }
  subscribeToShowEvents(arr) {
    const hostEl = this.hostEl.nativeElement;
    this.subs.add(this.renderer.listen(hostEl, arr[0].name, () => {
      this.popoverService.emitAnchorState(true, hostEl);
      arr[0].handler();
    }));
    this.subs.add(this.renderer.listen(hostEl, arr[1].name, e => {
      this.popoverService.emitAnchorState(false, null);
      arr[1].handler({
        domEvent: e
      });
    }));
  }
  subscribeClick() {
    if (this.disposeClickListener) {
      this.disposeClickListener();
    }
    this.disposeClickListener = this.renderer.listen(document, 'click', e => {
      this.onClick(e);
    });
  }
  /**
   * @hidden
   */
  onClick(event) {
    const isInsidePopup = !!closest(event.target, node => node.classList && node.classList.contains('k-popup'));
    const isAnchor = !!closest(event.target, node => node === this.hostEl.nativeElement);
    if (isInsidePopup || this.popupRef && isAnchor) {
      return;
    }
    if (isAnchor) {
      // on opening
      this.controlVisibility(this.hostEl.nativeElement, true);
    } else {
      // on closing
      this.controlVisibility(this.hostEl.nativeElement, false);
    }
  }
  controlVisibility(anchor, show) {
    if (this.isPrevented(anchor, show)) {
      return;
    }
    if (show) {
      this.show();
    } else {
      this.hide();
    }
  }
}
PopoverAnchorDirective.ɵfac = function PopoverAnchorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverAnchorDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1$1.PopupService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(PopoverService));
};
PopoverAnchorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverAnchorDirective,
  selectors: [["", "kendoPopoverAnchor", ""]],
  exportAs: ["kendoPopoverAnchor"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([PopoverService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverAnchorDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPopoverAnchor]',
      exportAs: 'kendoPopoverAnchor',
      providers: [PopoverService],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i1$1.PopupService
    }, {
      type: i0.Renderer2
    }, {
      type: PopoverService
    }];
  }, null);
})();

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Represents the [`kendoPopoverContainer`](slug:configuration_popover#toc-popover-container) directive.
 * It is used to filter and target multiple elements, which should display a popover on interaction.
 *
 * @example
 * ```ts-no-run
 * <div kendoPopoverContainer [popover]="myPopover" filter=".has-popover">
 *     <button class="has-popover">Show Popover</button>
 *     <button>Button Without Popover</button>
 *     <button class="has-popover">Show Popover</button>
 * </div>
 * ```
 */
class PopoverContainerDirective extends PopoverDirectivesBase {
  constructor(wrapperEl, ngZone, popupService, renderer, popoverService) {
    super(ngZone, popupService, renderer);
    this.wrapperEl = wrapperEl;
    this.ngZone = ngZone;
    this.popupService = popupService;
    this.renderer = renderer;
    this.popoverService = popoverService;
    this.mouseenterHandler = anchor => {
      this.controlVisibility(anchor, true);
    };
    this.mouseleaveHandler = args => {
      const anchor = args.anchor;
      if (this.isPrevented(anchor, false)) {
        return;
      }
      if (!this._hideSub) {
        this._hideSub = this.popoverService.hidePopover.subscribe(val => {
          const [isPopoverHovered,, isOriginAnchor, currentAnchor] = val;
          if (!isPopoverHovered && !isOriginAnchor) {
            this.hide();
            if (!isOriginAnchor && currentAnchor) {
              this.show(currentAnchor);
            }
          }
        });
      }
    };
    this.focusHandler = anchor => {
      this.controlVisibility(anchor, true);
    };
    this.blurHandler = args => {
      const anchor = args.anchor;
      const event = args.domEvent;
      if (this.isPrevented(anchor, false)) {
        return;
      }
      // from anchor to popup focus check
      const isFocusInside = !!closest(event.relatedTarget, node => node.classList && node.classList.contains('k-popover'));
      if (!isFocusInside) {
        this.hide();
      }
      if (!this._focusInsideSub) {
        // inside popup focus check
        this._focusInsideSub = this.popoverService.isFocusInsidePopover.pipe(filter(v => v !== null)).subscribe(val => {
          if (!val && !isFocusInside) {
            this.hide();
          }
        });
      }
    };
    this._popoverService = this.popoverService;
  }
  /**
   * Shows the Popover.
   *
   * @param anchor&mdash;Specifies the element that will be used as an anchor. The Popover opens relative to that element. [See example]({% slug programmaticcontrol_popover %})
   */
  show(anchor) {
    if (this.popupRef) {
      return;
    }
    this.ngZone.run(() => {
      this.openPopup(anchor);
    });
    this.popupRef.popupAnchorViewportLeave.pipe(take(1)).subscribe(() => this.hide());
  }
  /**
   * Toggles the visibility of the Popover. [See example]({% slug programmaticcontrol_popover %})
   *
   * @param anchor&mdash;Specifies the element that will be used as an anchor. The Popover opens relative to that element.
   */
  toggle(anchor) {
    const previousAnchor = this.popupRef && this.popupRef.content.instance.anchor;
    if (this.popupRef) {
      this.hide();
      if (previousAnchor !== anchor) {
        this.show(anchor);
      }
    } else {
      this.show(anchor);
    }
  }
  subscribeClick() {
    if (this.disposeClickListener) {
      this.disposeClickListener();
    }
    this.disposeClickListener = this.renderer.listen(document, 'click', e => {
      const filterElement = closestBySelector(e.target, this.filter);
      this.clickHandler(filterElement, e);
    });
  }
  subscribeToShowEvents(arr) {
    const filteredElements = Array.from(document.querySelectorAll(this.filter));
    filteredElements.forEach(el => {
      this.subs.add(this.renderer.listen(el, arr[0].name, () => {
        this.popoverService.emitAnchorState(true, el);
        arr[0].handler(el);
      }));
      this.subs.add(this.renderer.listen(el, arr[1].name, e => {
        this.popoverService.emitAnchorState(false, null);
        arr[1].handler({
          anchor: el,
          domEvent: e
        });
      }));
    });
  }
  clickHandler(anchor, event) {
    const isInsidePopup = !!closest(event.target, node => node.classList && node.classList.contains('k-popup'));
    const popupRefAnchor = this.popupRef && this.popupRef.content.instance.anchor;
    const isOriginAnchor = !!closest(event.target, node => node === (popupRefAnchor ? popupRefAnchor : anchor));
    if (this.showOn !== 'click' || isInsidePopup || this.popupRef && isOriginAnchor) {
      return;
    }
    if (!anchor && this.popupRef) {
      this.controlVisibility(anchor, false);
      return;
    }
    if (isOriginAnchor) {
      this.controlVisibility(anchor, true);
    } else if (this.popupRef) {
      this.controlVisibility(anchor, false);
      this.controlVisibility(anchor, true);
    }
  }
  controlVisibility(anchor, show) {
    if (this.isPrevented(anchor, show)) {
      return;
    }
    if (show) {
      this.show(anchor);
    } else {
      this.hide();
    }
  }
}
PopoverContainerDirective.ɵfac = function PopoverContainerDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverContainerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1$1.PopupService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(PopoverService));
};
PopoverContainerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PopoverContainerDirective,
  selectors: [["", "kendoPopoverContainer", ""]],
  inputs: {
    filter: "filter"
  },
  exportAs: ["kendoPopoverContainer"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([PopoverService]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverContainerDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPopoverContainer]',
      exportAs: 'kendoPopoverContainer',
      providers: [PopoverService],
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i1$1.PopupService
    }, {
      type: i0.Renderer2
    }, {
      type: PopoverService
    }];
  }, {
    filter: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class TooltipContentComponent {
  constructor(content, localizationService) {
    this.content = content;
    this.localizationService = localizationService;
    /**
     * @hidden
     */
    this.xIcon = xIcon;
    this.close = new EventEmitter();
    this.hostRole = 'tooltip';
    this.tooltipWidth = null;
    this.tooltipHeight = null;
    this.callout = true;
    this.calloutStyles = (position, calloutSize, isFlip) => {
      const styles = {};
      const isVertical = position === 'top' || position === 'bottom';
      const flipDeg = '180deg';
      const zeroDeg = '0deg';
      if (!isFlip) {
        styles.transform = isVertical ? `rotateX(${zeroDeg})` : `rotateY(${zeroDeg})`;
        return styles;
      }
      if (position === 'top') {
        styles.bottom = 'unset';
      } else if (position === 'bottom') {
        styles.top = 'unset';
      } else if (position === 'left') {
        styles.right = 'unset';
      } else if (position === 'right') {
        styles.left = 'unset';
      }
      styles[position] = `${-calloutSize}px`;
      styles.transform = isVertical ? `rotateX(${flipDeg})` : `rotateY(${flipDeg})`;
      return styles;
    };
    this.direction = localizationService.rtl ? 'rtl' : 'ltr';
  }
  get cssClasses() {
    return 'k-tooltip';
  }
  get hostId() {
    return this.tooltipId;
  }
  get className() {
    return this.closable;
  }
  get cssPosition() {
    return 'relative';
  }
  ngOnInit() {
    this.tooltipId = getId('tooltip');
    this.dynamicRTLSubscription = this.localizationService.changes.subscribe(({
      rtl
    }) => this.direction = rtl ? 'rtl' : 'ltr');
  }
  ngOnDestroy() {
    if (this.dynamicRTLSubscription) {
      this.dynamicRTLSubscription.unsubscribe();
    }
  }
  get closeButtonTitle() {
    return this.closeTitle || this.localizationService.get('closeTitle');
  }
  calloutPositionClass() {
    return {
      'top': 'k-callout-s',
      'left': 'k-callout-e',
      'bottom': 'k-callout-n',
      'right': 'k-callout-w'
    }[this.position];
  }
  onCloseClick(event) {
    event.preventDefault();
    this.close.emit();
  }
  updateCalloutPosition(position, isFlip) {
    if (!this.callout) {
      return;
    }
    const callout = this.content.nativeElement.querySelector('.k-callout');
    const isVertical = position === 'top' || position === 'bottom';
    const size = isVertical ? 'width' : 'height';
    const dir = isVertical ? 'left' : 'top';
    const offsetProperty = isVertical ? 'marginLeft' : 'marginTop';
    const calloutSize = callout.getBoundingClientRect()[size];
    const anchorCenter = getCenterOffset(this.anchor.nativeElement, dir, size);
    const contentCenter = getCenterOffset(this.content.nativeElement, dir, size);
    const diff = Math.abs(contentCenter - anchorCenter);
    if (diff > 1 || diff === 0 || Math.round(diff) === 0) {
      const newMargin = contentCenter - anchorCenter + calloutSize / 2;
      callout.style[offsetProperty] = `${-newMargin}px`;
    }
    const calloutStyles = this.calloutStyles(position, calloutSize, isFlip);
    Object.keys(calloutStyles).forEach(style => {
      callout.style[style] = calloutStyles[style];
    });
  }
}
TooltipContentComponent.ɵfac = function TooltipContentComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipContentComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.LocalizationService));
};
TooltipContentComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TooltipContentComponent,
  selectors: [["kendo-tooltip"]],
  hostVars: 13,
  hostBindings: function TooltipContentComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("dir", ctx.direction)("role", ctx.hostRole)("id", ctx.hostId);
      i0.ɵɵclassMap(ctx.cssClasses);
      i0.ɵɵstyleProp("position", ctx.cssPosition)("width", ctx.tooltipWidth, "px")("height", ctx.tooltipHeight, "px");
      i0.ɵɵclassProp("k-tooltip-closable", ctx.className);
    }
  },
  inputs: {
    tooltipWidth: "tooltipWidth",
    tooltipHeight: "tooltipHeight",
    titleTemplate: "titleTemplate",
    anchor: "anchor",
    closable: "closable",
    templateRef: "templateRef",
    templateString: "templateString"
  },
  outputs: {
    close: "close"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.tooltip'
  }]), i0.ɵɵStandaloneFeature],
  decls: 7,
  vars: 10,
  consts: () => {
    let i18n_0;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The title of the close button
       * @meaning kendo.tooltip.closeTitle
       */
      const MSG__USR_SRC_APP_NODE_MODULES__PROGRESS_KENDO_ANGULAR_TOOLTIP_FESM2020_PROGRESS_KENDO_ANGULAR_TOOLTIP_MJS_0 = goog.getMsg("Close");
      i18n_0 = MSG__USR_SRC_APP_NODE_MODULES__PROGRESS_KENDO_ANGULAR_TOOLTIP_FESM2020_PROGRESS_KENDO_ANGULAR_TOOLTIP_MJS_0;
    } else {
      i18n_0 = $localize`:kendo.tooltip.closeTitle|The title of the close button:Close`;
    }
    return [["kendoTooltipLocalizedMessages", "", "closeTitle", i18n_0], [1, "k-tooltip-content"], ["class", "k-tooltip-title", 4, "ngIf"], [3, "ngIf", "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "ngIf"], ["class", "k-tooltip-button", 3, "click", 4, "ngIf"], ["class", "k-callout", 3, "ngClass", 4, "ngIf"], [1, "k-tooltip-title"], [1, "k-tooltip-button", 3, "click"], ["href", "#", 1, "k-icon"], ["name", "x", 3, "svgIcon"], [1, "k-callout", 3, "ngClass"]];
  },
  template: function TooltipContentComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵelementStart(1, "div", 1);
      i0.ɵɵtemplate(2, TooltipContentComponent_div_2_Template, 2, 6, "div", 2)(3, TooltipContentComponent_ng_template_3_Template, 0, 0, "ng-template", 3)(4, TooltipContentComponent_ng_template_4_Template, 1, 1, "ng-template", 4);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(5, TooltipContentComponent_div_5_Template, 3, 3, "div", 5)(6, TooltipContentComponent_div_6_Template, 1, 1, "div", 6);
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.titleTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.templateRef)("ngTemplateOutlet", ctx.templateRef)("ngTemplateOutletContext", i0.ɵɵpureFunction2(7, _c5, ctx.anchor, ctx.anchor));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.templateString);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.closable);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.callout);
    }
  },
  dependencies: [LocalizedMessagesDirective, NgIf, NgTemplateOutlet, IconWrapperComponent, NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipContentComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-tooltip',
      template: `
        <ng-container kendoTooltipLocalizedMessages
            i18n-closeTitle="kendo.tooltip.closeTitle|The title of the close button"
            closeTitle="Close"
        >
        </ng-container>

        <div class="k-tooltip-content">
            <div class="k-tooltip-title" *ngIf="titleTemplate">
                <ng-template
                    [ngIf]="titleTemplate"
                    [ngTemplateOutlet]="titleTemplate"
                    [ngTemplateOutletContext]="{ $implicit: anchor, anchor: anchor }">
                </ng-template>
            </div>

            <ng-template
                [ngIf]="templateRef"
                [ngTemplateOutlet]="templateRef"
                [ngTemplateOutletContext]="{ $implicit: anchor, anchor: anchor }">
            </ng-template>
            <ng-template
                [ngIf]="templateString">
                {{ templateString }}
            </ng-template>
        </div>

        <div *ngIf="closable" [attr.aria-hidden]="true" class="k-tooltip-button" (click)="onCloseClick($event)">
            <a href="#" [attr.title]="closeButtonTitle" class="k-icon">
                <kendo-icon-wrapper
                    name="x"
                    [svgIcon]="xIcon">
                </kendo-icon-wrapper>
            </a>
        </div>

        <div class="k-callout" *ngIf="callout" [ngClass]="calloutPositionClass()"></div>
    `,
      providers: [LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.tooltip'
      }],
      standalone: true,
      imports: [LocalizedMessagesDirective, NgIf, NgTemplateOutlet, IconWrapperComponent, NgClass]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.LocalizationService
    }];
  }, {
    direction: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    close: [{
      type: Output
    }],
    cssClasses: [{
      type: HostBinding,
      args: ['class']
    }],
    hostRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    hostId: [{
      type: HostBinding,
      args: ['attr.id']
    }],
    className: [{
      type: HostBinding,
      args: ['class.k-tooltip-closable']
    }],
    cssPosition: [{
      type: HostBinding,
      args: ['style.position']
    }],
    tooltipWidth: [{
      type: HostBinding,
      args: ['style.width.px']
    }, {
      type: Input
    }],
    tooltipHeight: [{
      type: HostBinding,
      args: ['style.height.px']
    }, {
      type: Input
    }],
    titleTemplate: [{
      type: Input
    }],
    anchor: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    templateRef: [{
      type: Input
    }],
    templateString: [{
      type: Input
    }]
  });
})();

/**
 * Obsolete. Provide the TooltipSettings class instead.
 *
 * @hidden
 */
const TOOLTIP_SETTINGS = new InjectionToken('kendo-ui-tooltip-settings');
/**
 * Provides a global configuration for the Kendo UI Tooltip. Once injected through
 * the `AppComponent` constructor, the configuration properties can be overridden.
 *
 * @example
 * ```ts-no-run
 * import { TooltipSettings } from '@progress/kendo-angular-tooltip';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *      <div kendoTooltip>
 *          <button title="Saves the current document">Save</button>
 *      </div>`,
 *    providers: [{
 *        provide: TooltipSettings,
 *        useFactory: (): TooltipSettings => ({
 *          // Override default values of tooltips if wanted
 *          position: 'right'
 *        })
 *    }]
 * })
 * export class AppComponent { }
 * ```
 */
class TooltipSettings {
  /**
   * @hidden
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
TooltipSettings.ɵfac = function TooltipSettings_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipSettings)();
};
TooltipSettings.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TooltipSettings,
  factory: TooltipSettings.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipSettings, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * Represents the [Kendo UI Tooltip directive for Angular]({% slug overview_tooltip %}).
 * Used to display additional information that is related to an element.
 *
 * @example
 * ```ts-no-run
 * <div kendoTooltip>
 *    <a title="Tooltip title" href="foo">foo</a>
 * </div>
 * ```
 */
class TooltipDirective {
  constructor(tooltipWrapper, ngZone, renderer, popupService, settings, legacySettings) {
    this.tooltipWrapper = tooltipWrapper;
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.popupService = popupService;
    /**
     * Specifies a selector for elements within a container which will display a tooltip
     * ([see example]({% slug anchorelements_tooltip %})). The possible values include any
     * DOM `selector`. The default value is `[title]`.
     */
    this.filter = '[title]';
    /**
     * Specifies the position of the Tooltip that is relative to the
     * anchor element ([see example]({% slug positioning_tooltip %})).
     *
     * The possible values are:
     * * `top` (default)
     * * `bottom`
     * * `left`
     * * `right`
     */
    this.position = 'top';
    /**
     * Specifies the delay in milliseconds before the Tooltip is shown.
     * * `100` (default) milliseconds.
     */
    this.showAfter = 100;
    /**
     * Specifies if the Тooltip will display a callout arrow.
     *
     * The possible values are:
     * * `true` (default)
     * * `false`
     */
    this.callout = true;
    /**
     * Specifies if the Тooltip will display a **Close** button
     * ([see example]({% slug closable_tooltip %})).
     *
     * The possible values are:
     * * `true`
     * * `false`
     */
    this.closable = false;
    /**
     * Specifies the offset in pixels between the Tooltip and the anchor. Defaults to `6` pixels.
     * If the `callout` property is set to `true`, the offset is rendered from the callout arrow.
     * If the `callout` property is set to `false`, the offset is rendered from the content of the Tooltip.
     */
    this.offset = 6;
    this.anchor = null;
    this.keyboardNavigationSubscription = new Subscription();
    this.validPositions = ['top', 'bottom', 'right', 'left'];
    this.validShowOptions = ['hover', 'click', 'none'];
    validatePackage(packageMetadata);
    Object.assign(this, settings, legacySettings);
    this.ngZone.runOutsideAngular(() => {
      const wrapper = this.tooltipWrapper.nativeElement;
      this.anchorTitleSubscription = fromEvent(wrapper, 'mouseover').pipe(filter(() => this.filter !== '')).subscribe(e => {
        const filterElement = closestBySelector(e.target, this.filter);
        if (filterElement) {
          this.hideElementTitle({
            nativeElement: filterElement
          });
        }
      });
      this.mouseOverSubscription = fromEvent(wrapper, 'mouseover').pipe(filter(() => this.filter !== '')).subscribe(e => this.onMouseOver(e));
      this.mouseOutSubscription = fromEvent(wrapper, 'mouseout').subscribe(e => this.onMouseOut(e));
    });
  }
  /**
   * Sets the content of the Tooltip as a template reference
   * ([see example]({% slug templates_tooltip %})).
   */
  set tooltipTemplate(value) {
    this.template = value;
  }
  get tooltipTemplate() {
    return this.template;
  }
  /**
   * Shows the Tooltip.
   * @param anchor&mdash; ElementRef|Element.
   * Specifies the element that will be used as an anchor. The Tooltip opens relative to that element.
   */
  show(anchor) {
    if (this.popupRef) {
      return;
    }
    if (anchor instanceof Element) {
      anchor = {
        nativeElement: anchor
      };
    }
    this.anchor = anchor;
    if (this.showOn === 'hover') {
      if (this.popupRef) {
        return;
      }
      clearTimeout(this.showTimeout);
      this.showTimeout = setTimeout(() => this.showContent(this.anchor), this.showAfter);
    } else {
      this.hideElementTitle(this.anchor);
      this.showContent(this.anchor);
    }
  }
  /**
   * Hides the Tooltip.
   */
  hide() {
    clearTimeout(this.showTimeout);
    const anchor = this.anchor && this.anchor.nativeElement;
    if (anchor && anchor.getAttribute('data-title')) {
      if (!anchor.getAttribute('title') && anchor.hasAttribute('title')) {
        anchor.setAttribute('title', anchor.getAttribute('data-title'));
      }
      anchor.setAttribute('data-title', '');
    }
    if (this.popupMouseOutSubscription) {
      this.popupMouseOutSubscription.unsubscribe();
    }
    if (this.closeClickSubscription) {
      this.closeClickSubscription.unsubscribe();
    }
    this.closePopup();
  }
  /**
   * Toggle visibility of the Tooltip.
   *
   * @param anchor&mdash; ElementRef|Element. Specifies the element that will be used as an anchor.
   * @param show&mdash; Optional. Boolean. Specifies if the Tooltip will be rendered.
   */
  toggle(anchor, show) {
    const previousAnchor = this.anchor && this.anchor.nativeElement;
    if (anchor instanceof Element) {
      anchor = {
        nativeElement: anchor
      };
    }
    if (previousAnchor !== anchor.nativeElement) {
      this.hide();
    }
    if (previousAnchor === anchor.nativeElement && this.showOn === 'click') {
      this.hide();
    }
    if (typeof show === 'undefined') {
      show = !this.popupRef;
    }
    if (show) {
      this.show(anchor);
    } else {
      this.hide();
    }
  }
  ngOnInit() {
    if (this.showOn === undefined) {
      this.showOn = 'hover';
    }
    this.keyboardNavigationSubscription.add(this.renderer.listen(this.tooltipWrapper.nativeElement, 'keydown', event => this.onKeyDown(event)));
    this.verifyProperties();
  }
  ngOnChanges(changes) {
    if (changes.showOn && isDocumentAvailable()) {
      this.subscribeClick();
    }
  }
  ngAfterViewChecked() {
    if (!this.popupRef) {
      return;
    }
    if (this.anchor && !hasParent(this.anchor.nativeElement || this.anchor, this.tooltipWrapper.nativeElement)) {
      this.anchor = null;
      this.hide();
    }
  }
  ngOnDestroy() {
    this.hide();
    this.template = null;
    this.anchorTitleSubscription.unsubscribe();
    this.mouseOverSubscription.unsubscribe();
    this.mouseOutSubscription.unsubscribe();
    this.keyboardNavigationSubscription.unsubscribe();
    if (this.mouseClickSubscription) {
      this.mouseClickSubscription.unsubscribe();
    }
    if (this.popupPositionChangeSubscription) {
      this.popupPositionChangeSubscription.unsubscribe();
    }
    if (this.popupMouseOutSubscription) {
      this.popupMouseOutSubscription.unsubscribe();
    }
  }
  showContent(anchorRef) {
    if (!anchorRef.nativeElement.getAttribute('data-title') && !this.template) {
      return;
    }
    this.ngZone.run(() => {
      this.openPopup(anchorRef);
      this.bindContent(this.popupRef.content, anchorRef);
    });
    this.popupRef.popupAnchorViewportLeave.pipe(take(1)).subscribe(() => this.hide());
  }
  bindContent(contentComponent, anchorRef) {
    const content = contentComponent.instance;
    this.closeClickSubscription = content.close.subscribe(() => {
      this.hide();
    });
    if (!this.template) {
      content.templateString = this.anchor.nativeElement.getAttribute('data-title');
    } else {
      content.templateRef = this.template;
    }
    if (this.titleTemplate) {
      content.titleTemplate = this.titleTemplate;
    }
    content.closeTitle = this.closeTitle;
    content.anchor = anchorRef;
    content.callout = this.callout;
    content.closable = this.closable;
    content.position = this.position;
    content.tooltipWidth = this.tooltipWidth;
    content.tooltipHeight = this.tooltipHeight;
    this.popupRef.content.changeDetectorRef.detectChanges();
  }
  hideElementTitle(elementRef) {
    const element = elementRef.nativeElement;
    if (element.getAttribute('title')) {
      element.setAttribute('data-title', element.getAttribute('title'));
      element.setAttribute('title', '');
    }
  }
  openPopup(anchorRef) {
    const alignSettings = align(this.position, this.offset);
    const anchorAlign = alignSettings.anchorAlign;
    const popupAlign = alignSettings.popupAlign;
    const popupMargin = alignSettings.popupMargin;
    this.popupRef = this.popupService.open({
      anchor: anchorRef,
      anchorAlign,
      animate: false,
      content: TooltipContentComponent,
      collision: collision(this.collision, this.position),
      margin: popupMargin,
      popupAlign,
      popupClass: 'k-popup-transparent'
    });
    if (this.tooltipClass) {
      this.renderer.addClass(this.popupRef.popupElement, this.tooltipClass);
    }
    if (this.tooltipContentClass) {
      this.renderer.addClass(this.popupRef.content.instance['content'].nativeElement, this.tooltipContentClass);
    }
    const popupInstance = this.popupRef.content.instance;
    if (anchorRef) {
      this.renderer.setAttribute(anchorRef.nativeElement, 'aria-labelledby', popupInstance.tooltipId);
    }
    if (popupInstance.callout) {
      this.popupPositionChangeSubscription = this.popupRef.popupPositionChange.subscribe(({
        flip
      }) => {
        const isFlip = flip.horizontal === true || flip.vertical === true;
        popupInstance.updateCalloutPosition(this.position, isFlip);
      });
    }
    if (this.showOn === 'hover') {
      this.ngZone.runOutsideAngular(() => {
        const popup = this.popupRef.popupElement;
        this.popupMouseOutSubscription = fromEvent(popup, 'mouseout').subscribe(e => this.onMouseOut(e));
      });
    }
  }
  closePopup() {
    if (this.popupRef) {
      if (this.anchor) {
        this.renderer.removeAttribute(this.anchor.nativeElement, 'aria-labelledby');
      }
      this.popupRef.close();
      this.popupRef = null;
    }
    if (this.popupPositionChangeSubscription) {
      this.popupPositionChangeSubscription.unsubscribe();
    }
  }
  subscribeClick() {
    if (this.mouseClickSubscription) {
      this.mouseClickSubscription.unsubscribe();
    }
    if (this.showOn === 'click') {
      this.mouseClickSubscription = fromEvent(document, 'click').pipe(filter(() => this.filter !== '')).subscribe(e => this.onMouseClick(e, this.tooltipWrapper.nativeElement));
    }
  }
  onMouseClick(e, wrapper) {
    const target = e.target;
    const filterElement = closestBySelector(target, this.filter);
    const popup = this.popupRef && this.popupRef.popupElement;
    if (popup) {
      if (popup.contains(target)) {
        return;
      }
      if (this.closable) {
        return;
      }
    }
    if (wrapper.contains(target) && filterElement) {
      this.toggle(filterElement, true);
    } else if (popup) {
      this.hide();
    }
  }
  onKeyDown(event) {
    const keyCode = event.keyCode;
    const target = event.target;
    if (this.popupRef) {
      const tooltipId = this.popupRef.content.location.nativeElement.getAttribute('id');
      const anchorLabelledBy = target.getAttribute('aria-labelledby');
      if (keyCode === Keys.Escape && this.canCloseTooltip(target, tooltipId, anchorLabelledBy)) {
        this.closePopup();
      }
    }
  }
  canCloseTooltip(target, tooltipId, anchorLabelledBy) {
    const isIdEqualsLabel = tooltipId === anchorLabelledBy;
    const filterElement = closestBySelector(target, this.filter);
    const isTargetFocused = target === document.activeElement;
    const isTargetInsideWrapper = this.tooltipWrapper.nativeElement.contains(target);
    return isTargetInsideWrapper && filterElement && isTargetFocused && isIdEqualsLabel;
  }
  onMouseOver(e) {
    const filterElement = closestBySelector(e.target, this.filter);
    if (this.showOn !== 'hover') {
      return;
    }
    if (filterElement) {
      this.toggle(filterElement, true);
    }
  }
  onMouseOut(e) {
    if (this.showOn !== 'hover') {
      return;
    }
    if (this.closable) {
      return;
    }
    const popup = this.popupRef && this.popupRef.popupElement;
    const relatedTarget = e.relatedTarget;
    if (relatedTarget && this.anchor && contains(this.anchor.nativeElement, relatedTarget)) {
      return;
    }
    if (relatedTarget && contains(popup, relatedTarget)) {
      return;
    }
    this.hide();
  }
  verifyProperties() {
    if (!isDevMode()) {
      return;
    }
    if (!containsItem(this.validPositions, this.position)) {
      throw new Error(`Invalid value provided for position property.The available options are 'top', 'bottom', 'left', or 'right'.`);
    }
    if (!containsItem(this.validShowOptions, this.showOn)) {
      throw new Error(`Invalid value provided for showOn property.The available options are 'hover' or 'none'.`);
    }
  }
}
TooltipDirective.ɵfac = function TooltipDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i1$1.PopupService), i0.ɵɵdirectiveInject(TooltipSettings, 8), i0.ɵɵdirectiveInject(TOOLTIP_SETTINGS, 8));
};
TooltipDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TooltipDirective,
  selectors: [["", "kendoTooltip", ""]],
  inputs: {
    filter: "filter",
    position: "position",
    titleTemplate: "titleTemplate",
    showOn: "showOn",
    showAfter: "showAfter",
    callout: "callout",
    closable: "closable",
    offset: "offset",
    tooltipWidth: "tooltipWidth",
    tooltipHeight: "tooltipHeight",
    tooltipClass: "tooltipClass",
    tooltipContentClass: "tooltipContentClass",
    collision: "collision",
    closeTitle: "closeTitle",
    tooltipTemplate: "tooltipTemplate"
  },
  exportAs: ["kendoTooltip"],
  standalone: true,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoTooltip]',
      exportAs: 'kendoTooltip',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }, {
      type: i1$1.PopupService
    }, {
      type: TooltipSettings,
      decorators: [{
        type: Optional
      }]
    }, {
      type: TooltipSettings,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TOOLTIP_SETTINGS]
      }]
    }];
  }, {
    filter: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    titleTemplate: [{
      type: Input
    }],
    showOn: [{
      type: Input
    }],
    showAfter: [{
      type: Input
    }],
    callout: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    offset: [{
      type: Input
    }],
    tooltipWidth: [{
      type: Input
    }],
    tooltipHeight: [{
      type: Input
    }],
    tooltipClass: [{
      type: Input
    }],
    tooltipContentClass: [{
      type: Input
    }],
    collision: [{
      type: Input
    }],
    closeTitle: [{
      type: Input
    }],
    tooltipTemplate: [{
      type: Input
    }]
  });
})();

/**
 * Utility array that contains all `Tooltip` related components and directives
 */
const KENDO_TOOLTIP = [TooltipDirective, TooltipContentComponent, LocalizedMessagesDirective];
/**
 * Utility array that contains all `Popover` related components and directives
 */
const KENDO_POPOVER = [PopoverComponent, PopoverActionsTemplateDirective, PopoverBodyTemplateDirective, PopoverTitleTemplateDirective, PopoverAnchorDirective, PopoverContainerDirective];
/**
 * Utility array that contains all `@progress/kendo-angular-tooltip` related components and directives
 */
const KENDO_TOOLTIPS = [...KENDO_TOOLTIP, ...KENDO_POPOVER];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Tooltip component.
 *
 * The package exports:
 * - `KendoTooltipDirective`&mdash;The Tooltip directive class.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Tooltip module
 * import { TooltipModule } from '@progress/kendo-angular-tooltip';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, TooltipModule], // import TooltipModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
class TooltipModule {}
TooltipModule.ɵfac = function TooltipModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipModule)();
};
TooltipModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TooltipModule
});
TooltipModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [PopupService, ResizeBatchService, IconsService],
  imports: [TooltipContentComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_TOOLTIP],
      exports: [...KENDO_TOOLTIP],
      providers: [PopupService, ResizeBatchService, IconsService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Popover component.
 *
 * @example
 *
 * ```ts-no-run
 * import { PopoverModule } from '@progress/kendo-angular-tooltip';
 *
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { NgModule } from '@angular/core';
 *
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, PopoverModule],
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class PopoverModule {}
PopoverModule.ɵfac = function PopoverModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PopoverModule)();
};
PopoverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PopoverModule
});
PopoverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [PopupService, ResizeBatchService],
  imports: [PopoverComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_POPOVER],
      imports: [...KENDO_POPOVER],
      providers: [PopupService, ResizeBatchService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Tooltips components.
 *
 * @example
 *
 * ```ts-no-run
 * import { TooltipsModule } from '@progress/kendo-angular-tooltip';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * _@NgModule{{
 *    declarations: [AppComponent], // declare app component
 *    imports:      [BrowserModule, TooltipsModule], // import TooltipsModule module
 *    bootstrap:    [AppComponent]
 * }}
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 * ```
 */
class TooltipsModule {}
TooltipsModule.ɵfac = function TooltipsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipsModule)();
};
TooltipsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TooltipsModule
});
TooltipsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [PopupService, ResizeBatchService, IconsService],
  imports: [TooltipContentComponent, PopoverComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipsModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_TOOLTIPS],
      exports: [...KENDO_TOOLTIPS],
      providers: [PopupService, ResizeBatchService, IconsService]
    }]
  }], null, null);
})();

// Standalone Directives Arrays

/**
 * Generated bundle index. Do not edit.
 */

export { KENDO_POPOVER, KENDO_TOOLTIP, KENDO_TOOLTIPS, LocalizedMessagesDirective, PopoverActionsTemplateDirective, PopoverAnchorDirective, PopoverBodyTemplateDirective, PopoverComponent, PopoverContainerDirective, PopoverHiddenEvent, PopoverHideEvent, PopoverModule, PopoverShowEvent, PopoverShownEvent, PopoverTitleTemplateDirective, TOOLTIP_SETTINGS, TooltipContentComponent, TooltipDirective, TooltipModule, TooltipSettings, TooltipsModule };